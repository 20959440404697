import BasePage from "../../layout/BasePage";

export default function CreateCase() {
    return(
        <BasePage pageName={"Create case"} content={
            <CreateCaseForm />
        } />
    );
}

function CreateCaseForm() {
    return (
        <div>
            <div>
                <p>NaviPartner's new support system should be used instead of creating cases in Customer Portal.</p>
                <p>Please send us an e-mail with your inquiry: <a href="mailto:support@navipartner.com">support@navipartner.com</a></p>
            </div>
            <div className={"mt-4"} >
                <p>Additionally, you can find instructions on how to use NP Support Ticket System here <a href="https://www.navipartner.com/wp-content/uploads/NP_support_ticket_system-DK.pdf">DAN</a> and <a href="https://www.navipartner.com/wp-content/uploads/NP_support_ticket_system-EN.pdf">ENG</a>.</p>
            </div>
        </div>
    );
}
